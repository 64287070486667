import { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import TemplateTable from "shared/Template/Table/Table";
import InterfaceProjectTracker from "utilities/interface/elements/projectTracker.interface";
import classes from "./element.module.scss";

interface ElementProjectTrackerProps {
    element: InterfaceProjectTracker;
}

const ElementProjectTracker: FC<ElementProjectTrackerProps> = ({ element }) => {
    const { title, projects } = element;

    return (
        <TemplateTable
            heading={title}
            table={
                <div className={classes.wrapper}>
                    <table className={classes.projectTable}>
                        <tr>
                            <th>WorkStreams</th>
                            <th className={classes.statusCol}>Q1 Complete</th>
                            <th className={classes.statusCol}>Q1 In Progress</th>
                            <th className={classes.statusCol}>Q1 Behind Schedule</th>
                            <th className={classes.statusCol}>Q1 Not Started</th>
                        </tr>
                        {projects.nodes.map((item: any) => {
                            const { id, title, status } = item;
                            return (
                                <tr key={id}>
                                    <td>
                                        {!!item.linkPage.appRelativeLink && (
                                            <Link
                                                to={`/${item.linkPage.appRelativeLink}`}
                                            >
                                                <strong>{title}</strong>
                                            </Link>
                                        )}
                                        {!item.linkPage.appRelativeLink && (
                                            <strong>{title}</strong>
                                        )}
                                    </td>
                                    <td className={`${classes.noPadding} ${classes.productStatus}`}>
                                        {status === "Complete" && (
                                            <Fragment>
                                                {!!item.linkPage.appRelativeLink &&
                                                    <Link
                                                        className={classes.complete}
                                                        to={`/${item.linkPage.appRelativeLink}`}
                                                    >
                                                        <FaCheck size={20} />
                                                    </Link>
                                                }
                                                {!item.linkPage.appRelativeLink &&
                                                    <div className={classes.complete}>
                                                        <FaCheck size={20} />
                                                    </div>
                                                }
                                            </Fragment>
                                        )}
                                    </td>
                                    <td className={`${classes.noPadding} ${classes.productStatus}`}>
                                        {status === "In_Progress" && (
                                            <Fragment>
                                                {!!item.linkPage.appRelativeLink &&
                                                    <Link
                                                        className={classes.inprogress}
                                                        to={`/${item.linkPage.appRelativeLink}`}
                                                    >
                                                        <FaCheck size={20} />
                                                    </Link>
                                                }
                                                {!item.linkPage.appRelativeLink &&
                                                    <div className={classes.inprogress}>
                                                        <FaCheck size={20} />
                                                    </div>
                                                }
                                            </Fragment>
                                        )}
                                    </td>
                                    <td className={`${classes.noPadding} ${classes.productStatus}`}>
                                        {status === "Behind_Schedule" && (
                                            <Fragment>
                                                {!!item.linkPage.appRelativeLink &&
                                                    <Link
                                                        className={classes.behind}
                                                        to={`/${item.linkPage.appRelativeLink}`}
                                                    >
                                                        <FaCheck size={20} />
                                                    </Link>
                                                }
                                                {!item.linkPage.appRelativeLink &&
                                                    <div className={classes.behind}>
                                                        <FaCheck size={20} />
                                                    </div>
                                                }
                                            </Fragment>
                                        )}
                                    </td>
                                    <td className={`${classes.noPadding} ${classes.productStatus}`}>
                                        {status === "Not_Started" && (
                                            <Fragment>
                                                {!!item.linkPage.appRelativeLink &&
                                                    <Link
                                                        className={classes.notstarted}
                                                        to={`/${item.linkPage.appRelativeLink}`}
                                                    >
                                                        <FaCheck size={20} />
                                                    </Link>
                                                }
                                                {!item.linkPage.appRelativeLink &&
                                                    <div className={classes.notstarted}>
                                                        <FaCheck size={20} />
                                                    </div>
                                                }
                                            </Fragment>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </div>
            }
        ></TemplateTable>
    );
};

export default ElementProjectTracker;
